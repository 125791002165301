import React, { useState } from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import { StyledOfferCard } from "../../components/homepage/cards/offerCard/Style";
import PropertyImg1 from "../../assets/images/property-1.jpg";
import PropertyImg2 from "../../assets/images/property-2.jpg";
import PropertyImg3 from "../../assets/images/property-3.jpg";
import PropertyImg4 from "../../assets/images/property-4.jpg";
import badroom from "../../assets/images/icon-badroom-dark.svg";
import bathroom from "../../assets/images/icon-bathroom.svg";
import area from "../../assets/images/icon-area.svg";
import garage from "../../assets/images/icon-garage.svg";
import countryIcon from "../../assets/images/country.png";
import rupeeIcon from "../../assets/images/rupee-indian.png";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import Api from "../../admin/components/api/Api";
import OfferCard from "../homepage/cards/offerCard/OfferCard";
import axios from "axios";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import Spinner from "react-bootstrap/Spinner";
import AllMessage from "../common/allMessage/AllMessage";
import Pagination from "../../admin/components/pagination/Pagination";
import { Formik, Form, Field } from "formik";
import Select from "react-select";
function Properties() {
  const [isOpen, setIsOpen] = useState(false);
  const [allProperty, setAllProperty] = useState([]);
  const [propertyData, setPropertyData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Property Type");
  // const [showSearchForm, setShowSearchForm] = useState(false);

  const [address, setAddress] = useState("");
  const [keyboards, setKeyboards] = useState("");

  const pageSize = 1;

  const [areaInput, setAreaInput] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const [propertyTypes, setPropertyTypes] = useState([]);
  const [selectedPropertyType, setSelectedPropertyType] = useState("");

  const [propertyPurposes, setPropertyPurposes] = useState([]);

  const [selectedPurpose, setSelectedPurpose] = useState("buy");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [allCities, setAllCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [isCityDisabled, setIsCityDisabled] = useState(true);
  const [selectedState, setSelectedState] = useState("");
  const [selectedArea, setSelectedArea] = useState([]);

  const [propertyType, setPropertyType] = useState([]);

  const [assetUrl, setAssetUrl] = useState("");

  const [showAreaInput, setShowAreaInput] = useState(false);
  const [newArea, setNewArea] = useState("");

  const [propertySubtypes, setPropertySubtypes] = useState([]);
  const [selectedPropertySubtypes, setSelectedPropertySubtypes] = useState([]);

  const [priceRange, setPriceRange] = useState([1000, 100000]);
  const [sortBy, setSortBy] = useState("Price(low to high)");
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch states based on selected country
        const fetchStates = async (countryId) => {
          if (!countryId) {
            setStates([]);
            setIsCityDisabled(true);
            return;
          }
          try {
            const response = await axios.get(`${baseUrl}/all/states`);
            if (response.data.statusType === "SUCCESS") {
              setStates(response.data.states);

              setIsCityDisabled(false);
            } else {
              console.error("Error fetching states data:", response.data.text);
              setStates([]);
            }
          } catch (error) {
            console.error("Error fetching state list:", error);
            setStates([]);
            setIsCityDisabled(true);
          }
        };

        // Fetch cities based on selected state
        const fetchCities = async (stateId) => {
          if (!stateId) {
            setCities([]);
            return;
          }
          try {
            const response = await axios.get(`${baseUrl}/all/cities`, {
              page_number: 1,
              page_size: 20,
              order_by: "ASC",
            });
            if (response.data.statusType === "SUCCESS") {
              setCities(response.data.cities);
            } else {
              console.error("Error fetching cities data:", response.data.error);
              setCities([]);
            }
          } catch (error) {
            console.error("Error fetching cities data:", error);
            setCities([]);
          }
        };

        const fetchArea = async (cityId) => {
          if (!cityId) {
            setCities([]);
            return;
          }
          try {
            const response = await axios.get(`${baseUrl}/all/areas/`);
            if (response.data.statusType === "SUCCESS") {
              const areaData = response.data.areas.map((area) => ({
                label: area.name,
                value: area.name,
              }));
              setAreaInput([{ label: "Other", value: "other" }, ...areaData]);
            } else {
              console.error("Error fetching cities data:", response.data.error);
              setCities([]);
            }
          } catch (error) {
            console.error("Error fetching cities data:", error);
            setCities([]);
          }
        };

        fetchStates(1);
        fetchCities(selectedState);
        fetchArea(selectedCity);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [selectedState, selectedCity, selectedArea]);
  const handleSliderChange = (value) => {
    setMinValue(value[0]);
    setMaxValue(value[1]);
  };

  const handleOptionClick = (value) => {
    setSelectedOption(value);
    setIsOpen(false);
  };

  const [isPurposeOpen, setIsPurposeOpen] = useState(false);
  const [selectedPurposeOption, setSelectedPurposeOption] =
    useState("Property Status");

  const handlePurposeOptionClick = (value) => {
    setSelectedPurposeOption(value);
    setIsPurposeOpen(false);
  };

  const [value, setValue] = useState(50);
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const getPurposeApi = Api({
    url: "all/property/purposes",
  });
  const getTypesApi = Api({
    url: "all/property/types",
  });

  const getTypes = async () => {
    try {
      const responseData = await getTypesApi.fetchDataBySingleId();
      console.log(responseData);
      if (responseData.data.statusType === "SUCCESS") {
        setPropertyTypes(responseData?.data?.propertyTypes);
        console.log(responseData?.data?.propertyTypes);
      } else {
        setPropertyTypes([]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getPurpose = async () => {
    try {
      const responseData = await getPurposeApi.fetchDataBySingleId();
      console.log(responseData);
      if (responseData.data.statusType === "SUCCESS") {
        setPropertyPurposes(responseData?.data?.propertyPurposes);
      } else {
        setPropertyPurposes([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchSubtypes = async (propertyTypeId) => {
      if (!propertyTypeId) {
        setPropertySubtypes([]);
        return;
      }
      try {
        const response = await axios.get(
          `${baseUrl}/all/property/sub/types/${propertyTypeId}`
        );
        console.log(response);
        if (response.data.statusType === "SUCCESS") {
          setPropertySubtypes(response.data.propertySubTypes);
        } else {
          setPropertySubtypes([]);
        }
      } catch (error) {
        console.error("Error fetching property subtypes:", error);
        setPropertySubtypes([]);
      }
    };

    fetchSubtypes(selectedPropertyType);
  }, [selectedPropertyType]);
  useEffect(() => {
    try {
      fetchData();
      setIsLoading(true);
      getPurpose();
      getTypes();

      setIsLoading(false);
    } catch (error) {}
  }, []);

  useEffect(() => {
    const sortedArray = sortPropertyByPrice(allProperty, sortBy);
    setAllProperty(sortedArray);
  }, [sortBy]);

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  const sortPropertyByPrice = (properties, sortBy) => {
    let sortedArray = [];
    switch (sortBy) {
      case "Price(low to high)":
        sortedArray = [...properties].sort((a, b) => a.budget - b.budget);
        break;
      case "Price(high to low)":
        sortedArray = [...properties].sort((a, b) => b.budget - a.budget);
        break;
      default:
        sortedArray = properties;
        break;
    }
    return sortedArray;
  };

  const sortedProperty = sortPropertyByPrice("Price(low to high)");

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const fetchData = async (pageNumber, pageSize = 10) => {
    try {
      setIsLoading(true);
      console.log("your selectedPurpose is", selectedPurpose);
      const response = await axios.post(`${baseUrl}/all/lead/list`, {
        search_text: keyboards || "",
        type: selectedPropertyType,
        purpose: selectedPurpose,
        address: address || "",

        // min_price: 0,
        price: maxValue,
        page_number: pageNumber || 1,
        page_size: pageSize,
        order_by: "ASC",
      });
      console.log(response);
      if (response.data.statusType === "SUCCESS") {
        setAllProperty(response.data.properties);
        setAssetUrl(response.data.assetUrl);
        setTotalPages(response.data.totalPage);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const handleSearchFormSubmit = (e) => {
    e.preventDefault();
    setCurrentPage(0);
    fetchData();
  };

  const searchFetchData = async (values, pageNumber = 1, pageSize = 10) => {
    try {
      setIsLoading(true);
      const findPropertyTypeeById = propertyTypes.find(
        (singlePropertyType) => singlePropertyType.id === +values.propertyType
      );
      const findState = states.find((state) => state.id === +values.user_state);
      const findCity = cities.find((city) => city.id === +values.user_cities);

      const purposeData =
        values.propertyPurpose === "Buy"
          ? "Sell"
          : values.propertyPurpose === "Sell"
          ? "Buy"
          : values.propertyPurpose === "Rent"
          ? "Rent"
          : values.propertyPurpose === "Promotional"
          ? "Promotional"
          : "";
      const response = await axios.post(
        `${baseUrl}/all/lead/list`,
        {
          type: findPropertyTypeeById?.name,
          purpose: purposeData,
          // price: values.price[1],  Assuming maxValue is part of the price range
          price: 0,
          state: findState?.name,
          city: findCity?.name,
          area: selectedArea.map((area) => area.label).join(", "),
          page_number: pageNumber,
          page_size: pageSize,
          order_by: "ASC",
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.statusType === "SUCCESS") {
        setAllProperty(response.data.properties);
        setAssetUrl(response.data.assetUrl);
        setTotalPages(response.data.totalPage);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const formatPrice = (price) => {
    if (price < 100000) {
      // If price is less than 1 lakh, return it as is
      return `₹${price.toLocaleString()}`; // Format with comma
    } else if (price < 10000000) {
      // If price is 1 lakh or more, convert to lakhs
      const lakhs = (price / 100000).toFixed(2); // Show up to 2 decimal places
      return `₹${lakhs}L`; // Append 'L' for lakh
    } else {
      // If price is 1 lakh or more, convert to lakhs
      const lakhs = (price / 1000000).toFixed(2); // Show up to 2 decimal places
      return `₹${lakhs}C`; // Append 'L' for lakh
    }
  };

  const handleAddArea = async () => {};
  // Function for slider tooltip formatting

  const handleTipFormatter = (value) => {
    return formatPrice(value);
  };
  const [formValues, setFormValues] = useState({
    propertyPurpose: "Buy",
    user_state: "",
    user_cities: "",
    selectedArea: [],
    propertyType: "",
    propertySubtype: "",
    price: [1000, 100000],
  });
  return (
    <>
      {isLoading ? (
        <div className="spinner">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
          {allProperty.length === 0 ? (
            <section className="all-section">
              <Container fluid style={{ marginTop: "2rem", padding: "0rem 4rem" }}>
                <Row>
                <Col md={12}>
                    <div className="widget">
                      <div className="widget-boxed main-search-field">
                        <div className="widget-boxed-header">
                          <h4>Search Your Desired Property</h4>
                        </div>
                        <div className="trip-search">
                          {/* <form
                            className="form"
                            onSubmit={handleSearchFormSubmit}
                          >
                            <div className="form-nice-select looking">
                              <div className="first-select wide">
                                <div className="main-search-input-item">
                                  <input
                                    type="text"
                                    placeholder="Enter Keyword..."
                                    className="form-control"
                                    value={keyboards}
                                    onChange={(e) =>
                                      setKeyboards(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="form-nice-select looking">
                              <div className="first-select wide">
                                <div className="main-search-input-item">
                                  <input
                                    type="text"
                                    placeholder="address"
                                    className="form-control"
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                  />
                                </div>
                              </div>
                            </div>

                          
                            <div className="form-nice-select location">
                              <div className="dropdown-container">
                                <p>
                                  <i className="fa fa-home"></i> Filter by
                                  Property Type
                                </p>
                                <div
                                  className="dropdown-header"
                                  onClick={() => setIsOpen(!isOpen)}
                                >
                                  <span className="current">
                                    {selectedOption}
                                  </span>
                                  <i
                                    className={
                                      isOpen
                                        ? "fa fa-caret-up"
                                        : "fa fa-caret-down"
                                    }
                                  />
                                </div>
                                {isOpen && (
                                  <ul className="dropdown-list">
                                    {propertyTypes.map((propertyType) => (
                                      <li
                                        key={propertyType?.id}
                                        className="option"
                                        onClick={() => {
                                          handleOptionClick(propertyType?.name);
                                          setSelectedPropertyType(
                                            propertyType?.name
                                          );
                                        }}
                                      >
                                        {propertyType.name}
                                      </li>
                                    ))}
                                  </ul>
                                )}
                              </div>
                            </div>

                          
                            <div className="form-nice-select location">
                              <div className="dropdown-container">
                                <p>
                                  <i className="fa fa-home"></i> Filter by
                                  Property Status
                                </p>
                                <div
                                  className="dropdown-header"
                                  onClick={() =>
                                    setIsPurposeOpen(!isPurposeOpen)
                                  }
                                >
                                  <span className="current">
                                    {selectedPurposeOption}
                                  </span>
                                  <i
                                    className={
                                      isPurposeOpen
                                        ? "fa fa-caret-up"
                                        : "fa fa-caret-down"
                                    }
                                  />
                                </div>
                                {isPurposeOpen && (
                                  <ul className="dropdown-list">
                                    {propertyPurposes.map((purpose) => (
                                      <li
                                        key={purpose?.id}
                                        className="option"
                                        onClick={() => {
                                          handlePurposeOptionClick(
                                            purpose?.name
                                          );
                                          setSelectedPurpose(purpose?.name);
                                        }}
                                      >
                                        {purpose?.name}
                                      </li>
                                    ))}
                                  </ul>
                                )}
                              </div>
                            </div>

                            <div className="form-nice-select location">
                              <div className="range-slider">
                                <p>₹ Filter by Price</p>
                            
                                <Slider
                                  min={1000}
                                  max={100000}
                                  step={1000}
                                  range
                                  defaultValue={[1000, 100000]}
                                  onChange={handleSliderChange}
                                  tipFormatter={handleTipFormatter}
                                  tipProps={{
                                    placement: "top",
                                    visible: true,
                                    getTooltipContainer: () =>
                                      document.getElementById(
                                        "slider-container"
                                      ),
                                  }}
                                />
                                <div className="ui-slider-handle">
                                  <div className="first-slider-value">
                                    ₹{minValue}
                                  </div>
                                  <div className="last-slider-value">
                                    ₹{maxValue}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <button
                              className="btn btn-primary mt-3 w-100"
                              type="submit"
                            >
                              Search
                            </button>
                          </form> */}
                          <Formik
                            initialValues={formValues}
                            onSubmit={(values) => {
                              searchFetchData(values);
                              setFormValues(values);
                            }}
                          >
                            {({ setFieldValue, values }) => (
                              <Form className="form">
                                <Row>
                                  <Col md={12}>
                                    <div className="form-nice-select location">
                                      <div className="radio-container">
                                        <p>
                                          I Wish To{" "}
                                          <font
                                            className={"text-danger"}
                                            style={{ fontSize: "1.4rem" }}
                                          >
                                            *
                                          </font>
                                        </p>
                                        <div
                                          className="radio-group d-flex align-items-center justify-content-between"
                                          style={{ width: "50rem" }}
                                        >
                                          {propertyPurposes.map((purpose) => (
                                            <div
                                              key={purpose?.id}
                                              className="form-check"
                                            >
                                              <Field
                                                type="radio"
                                                name="propertyPurpose"
                                                id={`purpose-${purpose.id}`}
                                                value={purpose?.name}
                                                checked={
                                                  values.propertyPurpose ===
                                                  purpose?.name
                                                }
                                                onChange={() =>
                                                  setFieldValue(
                                                    "propertyPurpose",
                                                    purpose?.name
                                                  )
                                                }
                                                className="form-check-input"
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor={`purpose-${purpose.id}`}
                                              >
                                                {purpose?.name}
                                              </label>
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    </div>
                                  </Col>

                                  <Col md={6} className="mt-2">
                                    <div className="">
                                      <label
                                        htmlFor="user_state"
                                        className="form-label"
                                      >
                                        State
                                      </label>
                                      <Field
                                        as="select"
                                        name="user_state"
                                        className="form-select"
                                        onChange={(e) => {
                                          setSelectedState(e.target.value);
                                          setFieldValue(
                                            "user_state",
                                            e.target.value
                                          );
                                        }}
                                        value={values.user_state}
                                      >
                                        <option value="">
                                          -- Select State --
                                        </option>
                                        {states.map((state) => (
                                          <option
                                            key={state.id}
                                            value={state.id}
                                          >
                                            {state.name}
                                          </option>
                                        ))}
                                      </Field>
                                    </div>
                                  </Col>

                                  <Col md={6} className="mt-2">
                                    <div className="">
                                      <label
                                        htmlFor="user_cities"
                                        className="form-label"
                                      >
                                        City
                                      </label>
                                      <Field
                                        as="select"
                                        name="user_cities"
                                        className="form-select"
                                        onChange={(e) => {
                                          setSelectedCity(e.target.value);
                                          setFieldValue(
                                            "user_cities",
                                            e.target.value
                                          );
                                        }}
                                        value={values.user_cities}
                                      >
                                        <option value="">
                                          -- Select City --
                                        </option>
                                        {cities.map((city) => (
                                          <option key={city.id} value={city.id}>
                                            {city.name}
                                          </option>
                                        ))}
                                      </Field>
                                    </div>
                                  </Col>

                                  {/* <Col md={4} className="mt-2">
                                    <div className="">
                                      <label
                                        htmlFor="selectedArea"
                                        className="form-label"
                                      >
                                        Area
                                      </label>
                                      <Select
                                        options={areaInput}
                                        isMulti
                                        onChange={(option) => {
                                          if (
                                            option.some(
                                              (opt) => opt.value === "other"
                                            )
                                          ) {
                                            setShowAreaInput(true);
                                            setSelectedArea(option);
                                            setFieldValue(
                                              "selectedArea",
                                              option
                                            );
                                          } else {
                                            setSelectedArea(option);
                                            setShowAreaInput(false);
                                            setFieldValue(
                                              "selectedArea",
                                              option
                                            );
                                          }
                                        }}
                                        value={selectedArea}
                                        placeholder="Select Area"
                                        menuPortalTarget={document.body}
                                        disabled={!selectedCity}
                                        styles={{
                                          menuPortal: (base) => ({
                                            ...base,
                                            zIndex: 999999,
                                          }),
                                          valueContainer: (provided) => ({
                                            ...provided,
                                          }),
                                        }}
                                      />
                                      {showAreaInput && (
                                        <div className="mt-3">
                                          <input
                                            type="text"
                                            className="form-control mb-2"
                                            placeholder="Enter new area"
                                            value={newArea}
                                            onChange={(e) =>
                                              setNewArea(e.target.value)
                                            }
                                          />
                                          <Button
                                            variant="primary"
                                            onClick={handleAddArea}
                                            disabled={isLoading || !newArea}
                                          >
                                            {isLoading
                                              ? "Adding..."
                                              : "Add Area"}
                                          </Button>
                                          <Button
                                            style={{ marginLeft: "1rem" }}
                                            variant="secondary"
                                            onClick={() =>
                                              setShowAreaInput(false)
                                            }
                                            className="ml-2"
                                          >
                                            Cancel
                                          </Button>
                                        </div>
                                      )}
                                    </div>
                                  </Col> */}

                                  <Col md={4} className="mb-2">
                                    <div className="form-nice-select location">
                                      <div className="dropdown-container">
                                        <p>Property Type</p>
                                        <Field
                                          as="select"
                                          name="propertyType"
                                          className="form-select"
                                          onChange={(e) => {
                                            setFieldValue(
                                              "propertyType",
                                              e.target.value
                                            );
                                            setSelectedPropertyType(
                                              e.target.value
                                            );
                                          }}
                                          value={values.propertyType}
                                        >
                                          <option value="">
                                            Select Property type
                                          </option>
                                          {propertyTypes.map((propertyType) => (
                                            <option
                                              key={propertyType.id}
                                              value={propertyType.id}
                                            >
                                              {propertyType.name}
                                            </option>
                                          ))}
                                        </Field>
                                      </div>
                                    </div>
                                  </Col>

                                  <Col md={4} className="mt-4">
                                    <label
                                      htmlFor="propertySubtype"
                                      className="form-label"
                                    >
                                      Property Subtype
                                    </label>
                                    <Field
                                      as="select"
                                      name="propertySubtype"
                                      className="form-select"
                                      id="propertySubtype"
                                      onChange={(e) => {
                                        setFieldValue(
                                          "propertySubtype",
                                          e.target.value
                                        );
                                      }}
                                      value={values.propertySubtype}
                                    >
                                      <option value="">
                                        Select Property Subtype
                                      </option>
                                      {propertySubtypes.map((subtype) => (
                                        <option
                                          key={subtype.id}
                                          value={subtype.name}
                                        >
                                          {subtype.name}
                                        </option>
                                      ))}
                                    </Field>
                                  </Col>

                                  <Col md={4} className="mt-4">
                                    <div className="form-nice-select location">
                                      <div className="range-slider">
                                        <p>₹ Price</p>
                                        <Slider
                                          min={0}
                                          max={100000000} // Set maximum range to 1 crore
                                          step={10000} // Step size of 1000
                                          range
                                          value={values.price}
                                          onChange={(value) =>
                                            setFieldValue("price", value)
                                          }
                                          tipFormatter={handleTipFormatter}
                                          tipProps={{
                                            placement: "top",
                                            visible: true,
                                            getTooltipContainer: () =>
                                              document.getElementById(
                                                "slider-container"
                                              ),
                                          }}
                                        />
                                        <div className="ui-slider-handle">
                                          <div className="first-slider-value">
                                            {formatPrice(values.price[0])}
                                          </div>
                                          <div className="last-slider-value">
                                            {formatPrice(values.price[1])}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>

                                <button
                                  className="btn btn-primary mt-3 w-100"
                                  type="submit"
                                >
                                  Search
                                </button>
                              </Form>
                            )}
                          </Formik>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md={12}>
                    <Row className="headings-2 pt-0 align-items-center">
                      <div className="pro-wrapper">
                        <div className="detail-wrapper-body">
                          <div className="listing-title-bar">
                            <div className="text-heading text-left">
                              <p className="font-weight-bold mb-0 mt-3">
                                {allProperty.length} Search results
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="cod-pad single detail-wrapper mr-2 mt-0 d-flex justify-content-md-end align-items-center grid">
                          <div className="sort-list">
                            <label>
                              <i className="fa fa-align-left fs-16 pr-2"></i>
                              Sortby:
                            </label>
                            <select
                              className="form-control"
                              onChange={(e) => setSortBy(e.target.value)}
                            >
                              <option selected></option>
                              {/* <option value="Most Viewed">Most Viewed</option> */}
                              <option value="Price(low to high)">
                                Price(low to high)
                              </option>
                              <option value="Price(high to low)">
                                Price(high to low)
                              </option>
                            </select>
                          </div>
                          <div className="sorting-options">
                            <button
                              href="properties-list-1.html"
                              className="change-view-btn lde"
                            >
                              <i className="fa fa-th-list"></i>
                            </button>
                            <button className="change-view-btn active-view-btn">
                              <i className="fa fa-th-large"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </Row>
                    <Row>
                      {allProperty.map((singleProperty) => (
                        <Col lg={4} md={6} className="p-2">
                          <OfferCard
                            title={singleProperty?.name}
                            description={singleProperty?.description}
                            imageUrl={singleProperty?.images[0]?.image_url}
                            btnText="See"
                            type={singleProperty?.type?.name}
                            badroom={badroom}
                            bedroomsSize={singleProperty?.structure?.bed_rooms}
                            bathroom={bathroom}
                            bathroomSize={singleProperty?.structure?.bath_rooms}
                            area={area}
                            areaSize={singleProperty?.area?.city?.name}
                            countryIcon={countryIcon}
                            country={
                              singleProperty?.area?.city?.state?.country?.name
                            }
                            price={singleProperty?.price}
                            rupee={rupeeIcon}
                            to={"property-details"}
                            id={singleProperty?.id}
                          />
                        </Col>
                      ))}
                    </Row>
                    <Row className="mt-5 mb-5">
                      {/* <Pagination>
                        <Pagination.Prev
                          disabled={currentPage === 1}
                          onClick={() => handlePageChange(currentPage - 1)}
                        />
                        {Array.from({ length: totalPages }, (_, index) => (
                          <Pagination.Item
                            key={index}
                            active={index + 1 === currentPage}
                            onClick={() => handlePageChange(index + 1)}
                          >
                            {index + 1}
                          </Pagination.Item>
                        ))}
                        <Pagination.Next
                          disabled={currentPage === totalPages}
                          onClick={() => handlePageChange(currentPage + 1)}
                        />
                      </Pagination> */}
                    </Row>
                  </Col>
                </Row>
              </Container>
            </section>
          ) : (
            <section className="all-section">
              <Container fluid style={{ marginTop: "2rem", padding: "0rem 4rem" }}>
                <Row>
                  <Col md={12}>
                    <div className="widget">
                      <div className="widget-boxed main-search-field">
                        <div className="widget-boxed-header">
                          <h4>Search Your Desired Property</h4>
                        </div>
                        <div className="trip-search">
                          {/* <form
                            className="form"
                            onSubmit={handleSearchFormSubmit}
                          >
                            <div className="form-nice-select looking">
                              <div className="first-select wide">
                                <div className="main-search-input-item">
                                  <input
                                    type="text"
                                    placeholder="Enter Keyword..."
                                    className="form-control"
                                    value={keyboards}
                                    onChange={(e) =>
                                      setKeyboards(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="form-nice-select looking">
                              <div className="first-select wide">
                                <div className="main-search-input-item">
                                  <input
                                    type="text"
                                    placeholder="address"
                                    className="form-control"
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                  />
                                </div>
                              </div>
                            </div>

                          
                            <div className="form-nice-select location">
                              <div className="dropdown-container">
                                <p>
                                  <i className="fa fa-home"></i> Filter by
                                  Property Type
                                </p>
                                <div
                                  className="dropdown-header"
                                  onClick={() => setIsOpen(!isOpen)}
                                >
                                  <span className="current">
                                    {selectedOption}
                                  </span>
                                  <i
                                    className={
                                      isOpen
                                        ? "fa fa-caret-up"
                                        : "fa fa-caret-down"
                                    }
                                  />
                                </div>
                                {isOpen && (
                                  <ul className="dropdown-list">
                                    {propertyTypes.map((propertyType) => (
                                      <li
                                        key={propertyType?.id}
                                        className="option"
                                        onClick={() => {
                                          handleOptionClick(propertyType?.name);
                                          setSelectedPropertyType(
                                            propertyType?.name
                                          );
                                        }}
                                      >
                                        {propertyType.name}
                                      </li>
                                    ))}
                                  </ul>
                                )}
                              </div>
                            </div>

                          
                            <div className="form-nice-select location">
                              <div className="dropdown-container">
                                <p>
                                  <i className="fa fa-home"></i> Filter by
                                  Property Status
                                </p>
                                <div
                                  className="dropdown-header"
                                  onClick={() =>
                                    setIsPurposeOpen(!isPurposeOpen)
                                  }
                                >
                                  <span className="current">
                                    {selectedPurposeOption}
                                  </span>
                                  <i
                                    className={
                                      isPurposeOpen
                                        ? "fa fa-caret-up"
                                        : "fa fa-caret-down"
                                    }
                                  />
                                </div>
                                {isPurposeOpen && (
                                  <ul className="dropdown-list">
                                    {propertyPurposes.map((purpose) => (
                                      <li
                                        key={purpose?.id}
                                        className="option"
                                        onClick={() => {
                                          handlePurposeOptionClick(
                                            purpose?.name
                                          );
                                          setSelectedPurpose(purpose?.name);
                                        }}
                                      >
                                        {purpose?.name}
                                      </li>
                                    ))}
                                  </ul>
                                )}
                              </div>
                            </div>

                            <div className="form-nice-select location">
                              <div className="range-slider">
                                <p>₹ Filter by Price</p>
                            
                                <Slider
                                  min={1000}
                                  max={100000}
                                  step={1000}
                                  range
                                  defaultValue={[1000, 100000]}
                                  onChange={handleSliderChange}
                                  tipFormatter={handleTipFormatter}
                                  tipProps={{
                                    placement: "top",
                                    visible: true,
                                    getTooltipContainer: () =>
                                      document.getElementById(
                                        "slider-container"
                                      ),
                                  }}
                                />
                                <div className="ui-slider-handle">
                                  <div className="first-slider-value">
                                    ₹{minValue}
                                  </div>
                                  <div className="last-slider-value">
                                    ₹{maxValue}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <button
                              className="btn btn-primary mt-3 w-100"
                              type="submit"
                            >
                              Search
                            </button>
                          </form> */}
                          <Formik
                            initialValues={formValues}
                            onSubmit={(values) => {
                              searchFetchData(values);
                              setFormValues(values);
                            }}
                          >
                            {({ setFieldValue, values }) => (
                              <Form className="form">
                                <Row>
                                  <Col md={12}>
                                    <div className="form-nice-select location">
                                      <div className="radio-container">
                                        <p>
                                          I Wish To{" "}
                                          <font
                                            className={"text-danger"}
                                            style={{ fontSize: "1.4rem" }}
                                          >
                                            *
                                          </font>
                                        </p>
                                        <div
                                          className="radio-group d-flex align-items-center justify-content-between"
                                          style={{ width: "50rem" }}
                                        >
                                          {propertyPurposes.map((purpose) => (
                                            <div
                                              key={purpose?.id}
                                              className="form-check"
                                            >
                                              <Field
                                                type="radio"
                                                name="propertyPurpose"
                                                id={`purpose-${purpose.id}`}
                                                value={purpose?.name}
                                                checked={
                                                  values.propertyPurpose ===
                                                  purpose?.name
                                                }
                                                onChange={() =>
                                                  setFieldValue(
                                                    "propertyPurpose",
                                                    purpose?.name
                                                  )
                                                }
                                                className="form-check-input"
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor={`purpose-${purpose.id}`}
                                              >
                                                {purpose?.name}
                                              </label>
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    </div>
                                  </Col>

                                  <Col md={6} className="mt-2">
                                    <div className="">
                                      <label
                                        htmlFor="user_state"
                                        className="form-label"
                                      >
                                        State
                                      </label>
                                      <Field
                                        as="select"
                                        name="user_state"
                                        className="form-select"
                                        onChange={(e) => {
                                          setSelectedState(e.target.value);
                                          setFieldValue(
                                            "user_state",
                                            e.target.value
                                          );
                                        }}
                                        value={values.user_state}
                                      >
                                        <option value="">
                                          -- Select State --
                                        </option>
                                        {states.map((state) => (
                                          <option
                                            key={state.id}
                                            value={state.id}
                                          >
                                            {state.name}
                                          </option>
                                        ))}
                                      </Field>
                                    </div>
                                  </Col>

                                  <Col md={6} className="mt-2">
                                    <div className="">
                                      <label
                                        htmlFor="user_cities"
                                        className="form-label"
                                      >
                                        City
                                      </label>
                                      <Field
                                        as="select"
                                        name="user_cities"
                                        className="form-select"
                                        onChange={(e) => {
                                          setSelectedCity(e.target.value);
                                          setFieldValue(
                                            "user_cities",
                                            e.target.value
                                          );
                                        }}
                                        value={values.user_cities}
                                      >
                                        <option value="">
                                          -- Select City --
                                        </option>
                                        {cities.map((city) => (
                                          <option key={city.id} value={city.id}>
                                            {city.name}
                                          </option>
                                        ))}
                                      </Field>
                                    </div>
                                  </Col>

                                  {/* <Col md={4} className="mt-2">
                                    <div className="">
                                      <label
                                        htmlFor="selectedArea"
                                        className="form-label"
                                      >
                                        Area
                                      </label>
                                      <Select
                                        options={areaInput}
                                        isMulti
                                        onChange={(option) => {
                                          if (
                                            option.some(
                                              (opt) => opt.value === "other"
                                            )
                                          ) {
                                            setShowAreaInput(true);
                                            setSelectedArea(option);
                                            setFieldValue(
                                              "selectedArea",
                                              option
                                            );
                                          } else {
                                            setSelectedArea(option);
                                            setShowAreaInput(false);
                                            setFieldValue(
                                              "selectedArea",
                                              option
                                            );
                                          }
                                        }}
                                        value={selectedArea}
                                        placeholder="Select Area"
                                        menuPortalTarget={document.body}
                                        disabled={!selectedCity}
                                        styles={{
                                          menuPortal: (base) => ({
                                            ...base,
                                            zIndex: 999999,
                                          }),
                                          valueContainer: (provided) => ({
                                            ...provided,
                                          }),
                                        }}
                                      />
                                      {showAreaInput && (
                                        <div className="mt-3">
                                          <input
                                            type="text"
                                            className="form-control mb-2"
                                            placeholder="Enter new area"
                                            value={newArea}
                                            onChange={(e) =>
                                              setNewArea(e.target.value)
                                            }
                                          />
                                          <Button
                                            variant="primary"
                                            onClick={handleAddArea}
                                            disabled={isLoading || !newArea}
                                          >
                                            {isLoading
                                              ? "Adding..."
                                              : "Add Area"}
                                          </Button>
                                          <Button
                                            style={{ marginLeft: "1rem" }}
                                            variant="secondary"
                                            onClick={() =>
                                              setShowAreaInput(false)
                                            }
                                            className="ml-2"
                                          >
                                            Cancel
                                          </Button>
                                        </div>
                                      )}
                                    </div>
                                  </Col> */}

                                  <Col md={4} className="mb-2">
                                    <div className="form-nice-select location">
                                      <div className="dropdown-container">
                                        <p>Property Type</p>
                                        <Field
                                          as="select"
                                          name="propertyType"
                                          className="form-select"
                                          onChange={(e) => {
                                            setFieldValue(
                                              "propertyType",
                                              e.target.value
                                            );
                                            setSelectedPropertyType(
                                              e.target.value
                                            );
                                          }}
                                          value={values.propertyType}
                                        >
                                          <option value="">
                                            Select Property type
                                          </option>
                                          {propertyTypes.map((propertyType) => (
                                            <option
                                              key={propertyType.id}
                                              value={propertyType.id}
                                            >
                                              {propertyType.name}
                                            </option>
                                          ))}
                                        </Field>
                                      </div>
                                    </div>
                                  </Col>

                                  <Col md={4} className="mt-4">
                                    <label
                                      htmlFor="propertySubtype"
                                      className="form-label"
                                    >
                                      Property Subtype
                                    </label>
                                    <Field
                                      as="select"
                                      name="propertySubtype"
                                      className="form-select"
                                      id="propertySubtype"
                                      onChange={(e) => {
                                        setFieldValue(
                                          "propertySubtype",
                                          e.target.value
                                        );
                                      }}
                                      value={values.propertySubtype}
                                    >
                                      <option value="">
                                        Select Property Subtype
                                      </option>
                                      {propertySubtypes.map((subtype) => (
                                        <option
                                          key={subtype.id}
                                          value={subtype.name}
                                        >
                                          {subtype.name}
                                        </option>
                                      ))}
                                    </Field>
                                  </Col>

                                  <Col md={4} className="mt-4">
                                    <div className="form-nice-select location">
                                      <div className="range-slider">
                                        <p>₹ Price</p>
                                        <Slider
                                          min={0}
                                          max={100000000} // Set maximum range to 1 crore
                                          step={10000} // Step size of 1000
                                          range
                                          value={values.price}
                                          onChange={(value) =>
                                            setFieldValue("price", value)
                                          }
                                          tipFormatter={handleTipFormatter}
                                          tipProps={{
                                            placement: "top",
                                            visible: true,
                                            getTooltipContainer: () =>
                                              document.getElementById(
                                                "slider-container"
                                              ),
                                          }}
                                        />
                                        <div className="ui-slider-handle">
                                          <div className="first-slider-value">
                                            {formatPrice(values.price[0])}
                                          </div>
                                          <div className="last-slider-value">
                                            {formatPrice(values.price[1])}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>

                                <button
                                  className="btn btn-primary mt-3 w-100"
                                  type="submit"
                                >
                                  Search
                                </button>
                              </Form>
                            )}
                          </Formik>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md={12}>
                    <Row>
                      {allProperty.map((singleProperty) => (
                        <Col lg={4} md={6} className="p-2">
                          <OfferCard
                            title={singleProperty?.title}
                            description={singleProperty?.description}
                            images={singleProperty?.images}
                            assetUrl={assetUrl}
                            btnText="See"
                            type={singleProperty?.type?.name}
                            badroom={badroom}
                            bedroomsSize={singleProperty?.structure?.bed_rooms}
                            bathroom={bathroom}
                            bathroomSize={singleProperty?.structure?.bath_rooms}
                            area={area}
                            areaSize={singleProperty?.area?.city?.name}
                            countryIcon={countryIcon}
                            country={
                              singleProperty?.area?.city?.state?.country?.name
                            }
                            price={singleProperty?.budget}
                            rupee={rupeeIcon}
                            to={"property-details"}
                            id={singleProperty?.id}
                          />
                        </Col>
                      ))}
                    </Row>
                    <Row className="mt-5 mb-5">
                      <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                      />
                      {/* <Pagination>
                        <Pagination.Prev
                          disabled={currentPage === 1}
                          onClick={() => handlePageChange(currentPage - 1)}
                        />
                        {Array.from({ length: totalPages }, (_, index) => (
                          <Pagination.Item
                            key={index}
                            active={index + 1 === currentPage}
                            onClick={() => handlePageChange(index + 1)}
                          >
                            {index + 1}
                          </Pagination.Item>
                        ))}
                        <Pagination.Next
                          disabled={currentPage === totalPages}
                          onClick={() => handlePageChange(currentPage + 1)}
                        />
                      </Pagination> */}
                      {/* <Pagina */}
                    </Row>
                  </Col>
                </Row>
              </Container>
            </section>
          )}
        </>
      )}
    </>
  );
}

export default Properties;
