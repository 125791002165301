import React, { useState, useEffect } from "react";
import Api from "../../components/api/Api";
import { Col, Container, Row, Button } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Notification, {
  showToast,
} from "../../../components/common/notification/Notification";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import Spinner from "../../../components/common/spinner/Spinner";
import { useSelector } from "react-redux";
import SingleDetailHeader from "../../components/singleDetail/SingleDetailHeader";
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import AdminHeader from "../../common/Header";
const AddPropertyStatus = () => {
  const loggedInUser = useSelector((state) => state.auth.user);

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const addUserTypeApi = Api({
    url: `property/addStatus`,
  });

  const initialValues = {
    name: "",
    description: "",
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true);

      const data = {
        name: values.name,
        description: values.description,
      };

      const responseData = await addUserTypeApi.postData(data);
      if (responseData.data.statusType === "SUCCESS") {
        showToast("Property Status  added successfully", {
          type: "success",
        });

        setTimeout(() => {
          navigate("/property-status");
        }, 3000);
      }
      console.log(data);

      //   console.log(data);
    } catch (error) {
      setIsError(true);
      console.log(error);
      showToast("Error Occured", {
        type: "error",
      });
    } finally {
      setSubmitting(false);
    }
  };
  const breadcrumbItems = [
    { label: "", link: "/admin", icon: "fa-solid fa-house" },
    { label: "Master", link: "/user" },
    { label: "Property Statuses", link: "/property-status" },
    { label: "Add Property Status", link: "", active: true },
  ];
  return (
    <>
      <Notification />
      <Container fluid style={{ marginTop: "2rem", padding: "0rem 4rem" }}>
        <BreadCrumb items={breadcrumbItems} />
        <Row>
          <Col lg={3} md={4} sm={12}>
            <AdminHeader />
          </Col>
          <Col lg={9} md={8} sm={12}>
            <SingleDetailHeader
              header="Add Property Status"
              description="Add the property status details section"
            />
            <Formik
              initialValues={initialValues}
              // validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">
                      Name
                    </label>
                    <Field
                      type="text"
                      className="form-control"
                      id="name"
                      aria-describedby="emailHelp"
                      placeholder="Enter name"
                      name="name"
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="description" className="form-label">
                      Description
                    </label>
                    <Field
                      type="text"
                      className="form-control"
                      id="description"
                      aria-describedby="emailHelp"
                      placeholder="Enter Description"
                      name="description"
                    />
                    <ErrorMessage
                      name="title"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <button
                    type="submit"
                    className="btn btn-primary mt-5"
                    style={{ marginBottom: "10rem" }}
                    disabled={isSubmitting}
                    // disabled={true}
                  >
                    {isSubmitting ? (
                      <Spinner loading={true} />
                    ) : (
                      "Add Property Status"
                    )}
                  </button>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AddPropertyStatus;
