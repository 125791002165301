import React, { useState, useEffect } from "react";
import Api from "../../components/api/Api";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Notification, {
  showToast,
} from "../../../components/common/notification/Notification";
import Select from "react-select";
import { Link, useNavigate, useParams } from "react-router-dom";
import Spinner from "../../../components/common/spinner/Spinner";
import { useSelector } from "react-redux";
import SingleDetailHeader from "../../components/singleDetail/SingleDetailHeader";
import SingleDetail from "../../components/singleDetail/SingleDetail";
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import AdminHeader from "../../common/Header";

const CountryItem = () => {
  const loggedInUser = useSelector((state) => state.auth.user);
  const userId = loggedInUser?.userDetails?.id;
  const navigate = useNavigate();
  const { countryId } = useParams();

  const editPostApi = Api({
    url: `admin/editCountry`,
  });

  const getAllUserTypeApi = Api({
    url: `admin/countries`,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [selectedUserType, setSetelectedUserType] = useState("");
  const getUserTypeApi = Api({
    url: `admin/country/${selectedUserType}`,
  });
  const [formData, setFormData] = useState({
    name: "",
    description: "",
  });

  useEffect(() => {
    const getPurposes = async () => {
      try {
        const responseData = await getAllUserTypeApi.fetchData();
        if (responseData.data.statusType === "SUCCESS") {
          const purposeData = await responseData.data.countries.filter(
            (data) => data.id === +countryId
          );
          setSetelectedUserType(purposeData[0]?.name);
          console.log(purposeData);
        }
      } catch (error) {
        console.error(error);
      }
    };
    const fetchPostData = async () => {
      try {
        setIsLoading(true);
        const response = await getUserTypeApi.fetchDataBySingleId(true);
        if (response?.data?.statusType === "SUCCESS") {
          console.log(response.data.country);
          const postData = response.data.country;
          console.log(postData);
          setFormData({
            name: postData.name,
            description: postData.description,
          });
        }
      } catch (error) {
        setIsError(true);
        console.error(error);
        showToast("Error Occurred", { type: "error" });
      } finally {
        setIsLoading(false);
      }
    };

    getPurposes();
    fetchPostData();
  }, [selectedUserType]);
  const breadcrumbItems = [
    { label: "", link: "/admin", icon: "fa-solid fa-house" },
    { label: "Master", link: "/user" },
    { label: "Countries", link: "/country" },
    { label: "Country Details", link: "", active: true },
  ];
  return (
    <>
      <Notification />
      <Container fluid style={{ marginTop: "2rem", padding: "0rem 4rem" }}>
        <BreadCrumb items={breadcrumbItems} />
        <Row>
          <Col lg={3} md={4} sm={12}>
            <AdminHeader />
          </Col>
          <Col lg={9} md={8} sm={12}>
            <SingleDetailHeader
              header="Country Details"
              description="Country details section"
            />

            <Row>
              <Col>
                <div className="mb-3">
                  <SingleDetail label="Name" userDetail={formData?.name} />
                </div>
              </Col>
              <Col>
                <div className="mb-3">
                  <SingleDetail
                    label="Description"
                    userDetail={formData?.description}
                  />
                </div>
              </Col>
            </Row>
            <div>
              <Button className={`btn btn-primary mb-3 mt-3 ml-3`}>
                <Link className="text-white" to={`/country/${countryId}/edit`}>
                  Edit
                </Link>
              </Button>

              <Link
                style={{ marginLeft: "1rem" }}
                className="btn btn-primary mb-3 mt-3 ml-3"
                to={"/country"}
              >
                Back
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CountryItem;
