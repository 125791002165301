import React, { useState, useEffect } from "react";
import { Col, Container, Row, Button, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../Style.css";
import AdminHeader from "../../common/Header";
import List from "../../components/listing/List";
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import Api from "../../components/api/Api";
import { useSelector } from "react-redux";
import axios from "axios";
import Notification, {
  showToast,
} from "../../../components/common/notification/Notification";
import AllMessage from "../../../components/common/allMessage/AllMessage";
import Select from "react-select";
import Tr from "../../components/tr/Tr";
const Area = () => {
  const loggedInUser = useSelector((state) => state.auth.user);
  const userId = loggedInUser?.userDetails?.id;
  const token = loggedInUser?.token;
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [cityOptions, setCityOptions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const areaApi = Api({
    url: `admin/areas/${selectedCity}`,
  });
  const deleteQueryApi = Api({
    url: `admin/deleteCountry/1`,
  });
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);

  const handleDeleteAlertClose = () => setShowDeleteAlert(false);
  const handleDeleteAlertShow = () => setShowDeleteAlert(true);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const handleDelete = async (itemId) => {
    try {
      const response = await axios.delete(
        `${baseUrl}/admin/deleteArea/${itemId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      if (response.data.statusType === "SUCCESS") {
        setData(data.filter((item) => item.id !== itemId));
        showToast("Country deleted successfully", {
          type: "success",
        });
      } else {
        console.error(
          "Error 'deleting' item. Server responded with status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error 'deleting' item:", error);
    }
  };
  const [data, setData] = useState([]);
  const columns = [
    { displayName: "S.No.", render: (item, index) => index + 1 },
    { displayName: "Name", key: "name" },
  ];
  useEffect(() => {
    const fetchAreaData = async (pageNumber = 1, pageSize = 10) => {
      try {
        if (!selectedCity) return;

        const response = await axios.post(
          `${baseUrl}/admin/areas/${selectedCity}`,
          {
            page_number: pageNumber,
            page_size: pageSize,
            order_by: "ASC",
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.statusType === "SUCCESS") {
          setData(response.data.areas);
          setTotalPages(response.data.totalPage);
        } else {
          console.error("Error fetching areas data:", response.data.error);
          setData([]);
        }
      } catch (error) {
        console.error("Error fetching areas data:", error);
        setData([]); // Set data to empty array on error
      }
    };

    fetchAreaData(currentPage);
  }, [selectedCity, token, currentPage]);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const [statedata, setStateData] = useState([]);
  const stateApi = Api({
    url: `admin/states/${1}`,
  });
  useEffect(() => {
    const fetchStateData = async () => {
      try {
        const responseData = await stateApi.fetchData();
        console.log("your response data is", responseData);
        const stateData = responseData.data.states.map((state) => ({
          label: state.name,
          value: state.id,
        }));

        setStateData(stateData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchStateData();
  }, []);
  const postData = {
    page_number: 1,
    page_size: 20,
    order_by: "ASC",
  };
  useEffect(() => {
    const fetchCities = async () => {
      try {
        if (!selectedState) return;

        const response = await axios.post(
          `${baseUrl}/admin/cities/${selectedState.value}`,
          postData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data.statusType === "SUCCESS") {
          setCityOptions(
            response.data.cities.map((city) => ({
              label: city.name,
              value: city.id,
            }))
          );
        } else {
          console.error("Error fetching cities data:", response.data.error);
          setCityOptions([]);
        }
      } catch (error) {
        console.error("Error fetching cities data:", error);
        setCityOptions([]);
      }
    };

    fetchCities();
  }, [selectedState, token]);
  const initialValues = {};
  const breadcrumbItems = [
    { label: "", link: "/admin", icon: "fa-solid fa-house" },
    { label: "Master", link: "/user" },
    { label: "Areas", link: "/area", active: true },
  ];
  return (
    <>
      <Notification />
      <div className="content-body">
        <Container fluid style={{ padding: "0rem 2rem" }}>
          {/* Delete Alert */}
          {/* <BreadCrumb data="All Country" /> */}
          <BreadCrumb items={breadcrumbItems} />
          <Alert
            show={showDeleteAlert}
            variant="danger"
            onClose={handleDeleteAlertClose}
            dismissible
          >
            <Alert.Heading>Confirm Delete</Alert.Heading>
            <p>Are you sure you want to delete this user?</p>
            <div className="d-flex justify-content-end">
              <Button
                onClick={handleDeleteAlertClose}
                variant="outline-danger"
                className="me-3"
              >
                Cancel
              </Button>
              <Button onClick={handleDelete} variant="danger">
                Delete
              </Button>
            </div>
          </Alert>

          <Row>
            <Col lg={3} md={4} sm={12}>
              <AdminHeader />
            </Col>

            <Col lg={9} md={8} sm={12}>
              <Container>
                <Row>
                  <Col md={6}>
                    <div className="mb-3">
                      <label htmlFor="wish" className="form-label">
                        Select State
                      </label>
                      <Select
                        options={statedata}
                        onChange={setSelectedState}
                        value={selectedState}
                        placeholder="Select State"
                      />
                    </div>
                  </Col>
                  {selectedState === null ? (
                    <p>Please Select The State To Show The City...</p>
                  ) : (
                    <>
                      <Col md={6}>
                        <div className="mb-3">
                          <label htmlFor="wish" className="form-label">
                            Select City
                          </label>
                          <Select
                            options={cityOptions}
                            onChange={(selectedOption) =>
                              setSelectedCity(selectedOption.value)
                            }
                            value={selectedCity}
                            placeholder="Select City"
                          />
                        </div>
                      </Col>
                    </>
                  )}
                </Row>
              </Container>
              {selectedCity && selectedCity === null ? (
                <p>Please Select The City To Show The Area...</p>
              ) : (
                <>
                  {data?.length === 0 ? (
                    <>
                      <Tr
                        columns={columns}
                        initialValues={initialValues}
                        title="No Area Found"
                        buttonName="Create"
                        btnLink="/area/add"
                        titleHeader="Areas"
                      />
                      <Col
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                          marginTop: "1rem",
                        }}
                      >
                        {/* <Link to={"/area/add"} className="btn btn-primary">
                              No Area Found ! Please Add One Add Area
                            </Link> */}
                      </Col>
                    </>
                  ) : (
                    <List
                      title="Areas"
                      data={data}
                      columns={columns}
                      button="Create"
                      route={`area/${selectedState && selectedState.value}`}
                      // route={`area/${selectedState && selectedState.value}`}
                      to="add"
                      handleDelete={handleDelete}
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={handlePageChange}
                    />
                  )}
                </>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
export default Area;
