import React from "react";
import { NavLink, Link } from "react-router-dom";

import Container from "react-bootstrap/Container";
import { Row } from "react-bootstrap";

import "swiper/css";
import Col from "react-bootstrap/Col";

import PropertyHomeIcon from "../../assets/images/icon-how-1.svg";
import RetailerHomeIcon from "../../assets/images/icon-how-2.svg";
import DocumentsHomeIcon from "../../assets/images/icon-how-3.svg";
import keysHomeIcon from "../../assets/images/icon-how-4.svg";

const HowItWorks = () => {
  return (
    <>
      <section className="how-it-works no-bg">
        <Container>
          <Row>
            <Col md={12}>
              <div className="section-title">
                <h3>HOW IT WORKS</h3>
                <h2 className="text-anime">Find a Perfect Home</h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6} lg={3}>
              <div className="how-it-work-item">
                <div className="icon-box">
                  <img src={PropertyHomeIcon} alt="" />
                </div>

                <h3>Find Property</h3>
                <p>
                  Find Your Dream Property in India Explore a wide range of real
                  estate properties available for sale or rent in your desired
                  location across India. Our comprehensive property platform
                  offers a seamless search experience to help you find the
                  perfect home, apartment, or commercial space that meets your
                  specific needs and preferences.
                </p>
              </div>
            </Col>
            <Col md={6} lg={3}>
              <div className="how-it-work-item">
                <div className="icon-box">
                  <img src={RetailerHomeIcon} alt="" />
                </div>

                <h3>Meet Retailer</h3>
                <p>
                  Connect with Trusted Real Estate Experts Collaborate with
                  experienced real estate retailers, agents, and brokers who
                  specialize in the properties you're interested in, whether
                  it's apartments, houses, or commercial spaces in India. Our
                  platform connects you with knowledgeable professionals who can
                  provide personalized assistance and guidance throughout the
                  entire buying, selling, or renting process.
                </p>
              </div>
            </Col>
            <Col md={6} lg={3}>
              <div className="how-it-work-item">
                <div className="icon-box">
                  <img src={DocumentsHomeIcon} alt="" />
                </div>

                <h3>Documents</h3>
                <p>
                  Streamline Your Real Estate Transactions Simplify the
                  paperwork and documentation process with our suite of
                  streamlined tools and resources. Manage all necessary real
                  estate documents efficiently, ensuring a smooth and
                  hassle-free transaction.
                </p>
              </div>
            </Col>
            <Col md={6} lg={3}>
              <div className="how-it-work-item">
                <div className="icon-box">
                  <img src={keysHomeIcon} alt="" />
                </div>

                <h3>Take the keys</h3>
                <p>
                  Smooth Transition to Your New Home Once all the paperwork and
                  documentation are finalized, it's time to take possession of
                  your new property and receive the keys. Enjoy the excitement
                  of moving into your dream home, apartment, or commercial space
                  in India, hassle-free.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default HowItWorks;
