import React, { useState, useEffect } from "react";
import Api from "../../components/api/Api";
import { Col, Container, Row, Button } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Notification, {
  showToast,
} from "../../../components/common/notification/Notification";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import Spinner from "../../../components/common/spinner/Spinner";
import { useSelector } from "react-redux";
import SingleDetailHeader from "../../components/singleDetail/SingleDetailHeader";
import axios from "axios";
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import AdminHeader from "../../common/Header";
const AddState = () => {
  const loggedInUser = useSelector((state) => state.auth.user);
  const token = loggedInUser?.token;
  const userId = loggedInUser?.userDetails?.id;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const addStateApi = Api({
    url: `admin/addState`,
  });

  const initialValues = {
    name: "",
    description: "",
    country: {
      id: "",
    },
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const authToken = await loggedInUser?.token; // Add your authToken here
        const countryResponse = await axios.get(`${baseUrl}/admin/countries`, {
          headers: { Authorization: `Bearer ${authToken}` },
        });
        if (countryResponse.data.statusType === "SUCCESS") {
          const defaultCountry = countryResponse.data.countries.find(
            (country) => country.name === "India"
          );
          setCountries(countryResponse.data.countries);
          setSelectedCountry(defaultCountry ? defaultCountry.id : "");
        } else {
          setCountries([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true);

      const data = {
        name: values.name.toUpperCase(),
        description: values.description,
        country: { id: +values.country },
      };

      const responseData = await addStateApi.postData(data);
      if (responseData.data.statusType === "SUCCESS") {
        showToast("State  added successfully", {
          type: "success",
        });

        setTimeout(() => {
          navigate("/state");
        }, 3000);
      }
      console.log(data);

      //   console.log(data);
    } catch (error) {
      setIsError(true);
      console.log(error);
      showToast("Error Occured", {
        type: "error",
      });
    } finally {
      setSubmitting(false);
    }
  };

  const breadcrumbItems = [
    { label: "", link: "/admin", icon: "fa-solid fa-house" },
    { label: "Master", link: "/user" },
    { label: "States", link: "/state" },
    { label: "Add State", link: "", active: true },
  ];
  return (
    <>
      <Notification />
      <Container fluid style={{ marginTop: "2rem", padding: "0rem 4rem" }}>
        <BreadCrumb items={breadcrumbItems} />
        <Row>
          <Col lg={3} md={4} sm={12}>
            <AdminHeader />
          </Col>
          <Col lg={9} md={8} sm={12}>
            <SingleDetailHeader
              header="Add State"
              description="Add the state details section"
            />
            <Formik
              initialValues={initialValues}
              // validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">
                      Name
                    </label>
                    <Field
                      type="text"
                      className="form-control"
                      id="name"
                      aria-describedby="emailHelp"
                      placeholder="Enter name"
                      name="name"
                    />
                    <ErrorMessage
                      name="title"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="description" className="form-label">
                      Description
                    </label>
                    <Field
                      type="text"
                      className="form-control"
                      id="description"
                      aria-describedby="emailHelp"
                      placeholder="Enter Description"
                      name="description"
                    />
                    <ErrorMessage
                      name="title"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <Col md={12}>
                    <div className="">
                      <label htmlFor="title" className="form-label">
                        Country
                      </label>
                      <Field
                        as="select"
                        className="form-control"
                        name="country"
                        required=""
                      >
                        <option value="">-- Select Country --</option>
                        {countries.map((country) => (
                          <option key={country.id} value={country.id}>
                            {country.name}
                          </option>
                        ))}
                      </Field>
                    </div>
                  </Col>
                  <button
                    type="submit"
                    className="btn btn-primary mt-5"
                    style={{ marginBottom: "10rem" }}
                    disabled={isSubmitting}
                    // disabled={true}
                  >
                    {isSubmitting ? <Spinner loading={true} /> : "Add State"}
                  </button>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AddState;
