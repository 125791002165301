import React, { useState, useEffect } from "react";
import Api from "../../components/api/Api";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Notification, {
  showToast,
} from "../../../components/common/notification/Notification";
import Select from "react-select";
import { Link, useNavigate, useParams } from "react-router-dom";
import Spinner from "../../../components/common/spinner/Spinner";
import { useSelector } from "react-redux";
import axios from "axios";
import SingleDetailHeader from "../../components/singleDetail/SingleDetailHeader";
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import AdminHeader from "../../common/Header";
const EditState = () => {
  const loggedInUser = useSelector((state) => state.auth.user);
  const userId = loggedInUser?.userDetails?.id;
  const navigate = useNavigate();
  const { stateId } = useParams();
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const editPostApi = Api({
    url: `admin/editState`,
  });

  const getAllUserTypeApi = Api({
    url: `admin/states/${1}`,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [selectedUserType, setSetelectedUserType] = useState("");
  const getUserTypeApi = Api({
    url: `admin/state/${selectedUserType}`,
  });
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    country: {
      id: "",
    },
  });

  useEffect(() => {
    const getPurposes = async () => {
      try {
        const responseData = await getAllUserTypeApi.fetchData();
        if (responseData.data.statusType === "SUCCESS") {
          const purposeData = await responseData.data.states.filter(
            (data) => data.id === +stateId
          );
          setSetelectedUserType(purposeData[0]?.name);
          console.log(purposeData);
        }
      } catch (error) {
        console.error(error);
      }
    };
    const fetchPostData = async () => {
      try {
        setIsLoading(true);
        const response = await getUserTypeApi.fetchData();
        if (response?.data?.statusType === "SUCCESS") {
          console.log(response);
          const postData = response.data.state;
          console.log(postData);
          setFormData({
            name: postData.name,
            description: postData.description,
          });
        }
      } catch (error) {
        setIsError(true);
        console.error(error);
        showToast("Error Occurred", { type: "error" });
      } finally {
        setIsLoading(false);
      }
    };
    const fetchData = async () => {
      try {
        const authToken = await loggedInUser?.token; // Add your authToken here
        const countryResponse = await axios.get(`${baseUrl}/admin/countries`, {
          headers: { Authorization: `Bearer ${authToken}` },
        });
        console.log(countryResponse);
        if (countryResponse.data.statusType === "SUCCESS") {
          const countryData = countryResponse.data.countries.map((country) => ({
            label: country.name,
            value: country.id,
          }));
          setCountries(countryData);
        } else {
          setCountries([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    getPurposes();
    fetchPostData();
    fetchData();
  }, [selectedUserType]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setIsLoading(true);
      console.log(selectedCountry);
      const data = {
        id: stateId,
        name: formData.name,
        description: formData.description,
        country: { id: +selectedCountry.value },
      };

      console.log(data);

      const responseData = await editPostApi.editData(data);
      if (responseData.data.statusType === "SUCCESS") {
        showToast("State updated successfully", { type: "success" });
        setTimeout(() => {
          navigate("/state");
        }, 3000);
      }
    } catch (error) {
      setIsError(true);
      console.error(error);
      showToast("Error Occurred", { type: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const breadcrumbItems = [
    { label: "", link: "/admin", icon: "fa-solid fa-house" },
    { label: "Master", link: "/user" },
    { label: "States", link: "/state" },
    { label: "Edit State Details", link: "", active: true },
  ];

  return (
    <>
      <Notification />
      <Container fluid style={{ marginTop: "2rem", padding: "0rem 4rem" }}>
        <BreadCrumb items={breadcrumbItems} />
        <Row>
          <Col lg={3} md={4} sm={12}>
            <AdminHeader />
          </Col>
          <Col lg={9} md={8} sm={12}>
            <SingleDetailHeader
              header="Edit State Details"
              description="Edit State details section"
            />
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <div className="mb-3">
                    <label htmlFor="title" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="title"
                      placeholder="Enter Name"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="mb-3">
                    <label htmlFor="description" className="form-label">
                      Description
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="description"
                      placeholder="Enter Description"
                      name="description"
                      value={formData.description}
                      onChange={handleInputChange}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <div className="mb-3">
                  <label htmlFor="wish" className="form-label">
                    Select Country
                  </label>
                  <Select
                    options={countries}
                    onChange={setSelectedCountry}
                    value={selectedCountry}
                    placeholder="Select Country"
                  />
                </div>
              </Row>

              <Button variant="primary" type="submit" className="mt-5 mb-5">
                {isLoading ? <Spinner loading={true} /> : "Update"}
              </Button>
              <Link
                style={{ marginLeft: "1rem" }}
                className="btn btn-primary mb-5 mt-5"
                to={"/state"}
              >
                Back
              </Link>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EditState;
