import React, { useEffect } from "react";
import { Container } from "react-bootstrap";

import AddPost from "../post/AddPost";
import TabMenu from "./TabMenu";
import PostBasicPropertyForm from "../post/PostBasicPropertyForm";
import { useDispatch } from "react-redux";
import { resetForm } from "../../../features/formData/formSlice";

const Tab4 = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(resetForm());
  }, []);
  return (
    <>
      <Container fluid style={{ marginTop: "2rem", padding: "0rem 4rem" }}>
        <TabMenu />
      </Container>
      <PostBasicPropertyForm showThings={false} isPostFromDashboard={true} />
      {/* <AddPost showThings={false} isPostFromDashboard={true} /> */}
    </>
  );
};

export default Tab4;
