import React from "react";
import { Link } from "react-router-dom";

import Container from "react-bootstrap/Container";
import { Row } from "react-bootstrap";
import "swiper/css";
import Col from "react-bootstrap/Col";
import Button from "../common/button/Button";

const InfoBar = () => {
  return (
    <>
      <section className="infobar-cta">
        <Container>
          <Row>
            <Col md={12}>
              <div className="infobar-box">
                <div className="row align-items-center">
                  <Col lg={7}>
                    <div className="cta-content">
                      <h2 className="text-anime">
                        Need Help? Talk to Our Expert.
                      </h2>
                      <p className="wow fadeInUp">
                        Need assistance with your project? Our team of experts
                        is here to help you every step of the way. Whether you
                        have questions, need advice, or want to discuss your
                        project requirements, we're just a phone call away.
                      </p>
                    </div>
                  </Col>

                  <Col lg={5}>
                    <div className="cta-button">
                      <Button
                
                       
                      >
                        <i className="fa-solid fa-phone-volume"></i> 
                        <a  className="btn-default btn-border" href="tel:+919818961783">
                        +91
                        9818961783
                        </a>
                     
                      </Button>
                      <Link to="/contactus" className="btn-default">
                        Contact Now
                      </Link>
                    </div>
                  </Col>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default InfoBar;
