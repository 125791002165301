import React, { useState, useEffect } from "react";
import { Col, Container, Row, Button, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../Style.css";
import AdminHeader from "../../common/Header";
import List from "../../components/listing/List";
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import Api from "../../components/api/Api";
import { useSelector } from "react-redux";
import axios from "axios";
import Notification, {
  showToast,
} from "../../../components/common/notification/Notification";
import AllMessage from "../../../components/common/allMessage/AllMessage";
import Select from "react-select";
import Tr from "../../components/tr/Tr";
const City = () => {
  const loggedInUser = useSelector((state) => state.auth.user);
  const userId = loggedInUser?.userDetails?.id;
  const token = loggedInUser?.token;
  const [selectedCountry, setSelectedCity] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  console.log(selectedState);
  const cityApi = Api({
    url: `admin/cities/${selectedState?.value}`,
  });
  const stateApi = Api({
    url: `admin/states/${1}`,
  });
  const deleteQueryApi = Api({
    url: `admin/deleteState`,
  });
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const handleDeleteAlertClose = () => setShowDeleteAlert(false);
  const handleDeleteAlertShow = () => setShowDeleteAlert(true);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const handleDelete = async (itemId) => {
    try {
      const response = await axios.get(
        `${baseUrl}/admin/deleteCity/${itemId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      if (response.data.statusType === "SUCCESS") {
        setData(data.filter((item) => item.id !== itemId));
        showToast("City deleted successfully", {
          type: "success",
        });
      } else {
        console.error(
          "Error 'deleting' item. Server responded with status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error 'deleting' item:", error);
    }
  };
  const [data, setData] = useState([]);
  const [statedata, setStateData] = useState([]);
  const columns = [
    { displayName: "S.No.", render: (item, index) => index + 1 },
    { displayName: "Name", key: "name" },
  ];
  useEffect(() => {
    const fetchStateData = async () => {
      try {
        const responseData = await stateApi.fetchData();
        console.log("your state data is", responseData);
        const stateData = responseData.data.states.map((state) => ({
          label: state.name,
          value: state.id,
        }));
        setStateData(stateData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchStateData();
  }, []);
  const fetchPropertyData = async (pageNumber = 1) => {
    try {
      const responseData = await cityApi.fetchData(pageNumber);
      console.log(responseData);
      setData(responseData.data.cities);
      setTotalPages(responseData.data.totalPage);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchPropertyData(currentPage);
  }, [selectedState, currentPage]);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const initialValues = {};
  const breadcrumbItems = [
    { label: "", link: "/admin", icon: "fa-solid fa-house" },
    { label: "Master", link: "/user" },
    { label: "Cities", link: "/city", active: true },
  ];
  return (
    <>
      <Notification />
      <div className="content-body">
        <Container fluid style={{ padding: "0rem 2rem" }}>
          {/* Delete Alert */}

          <BreadCrumb items={breadcrumbItems} />
          <Alert
            show={showDeleteAlert}
            variant="danger"
            onClose={handleDeleteAlertClose}
            dismissible
          >
            <Alert.Heading>Confirm Delete</Alert.Heading>
            <p>Are you sure you want to delete this user?</p>
            <div className="d-flex justify-content-end">
              <Button
                onClick={handleDeleteAlertClose}
                variant="outline-danger"
                className="me-3"
              >
                Cancel
              </Button>
              <Button onClick={handleDelete} variant="danger">
                Delete
              </Button>
            </div>
          </Alert>

          <Row>
            <Col lg={3} md={4} sm={12}>
              <AdminHeader />
            </Col>

            <Col lg={9} md={8} sm={12}>
              <Container>
                <Row>
                  <Col lg={9} md={8} sm={12}>
                    <div className="mb-3">
                      <label htmlFor="wish" className="form-label">
                        Select State
                      </label>
                      <Select
                        options={statedata}
                        onChange={setSelectedState}
                        value={selectedState}
                        placeholder="Select City"
                      />
                    </div>
                  </Col>
                </Row>
              </Container>
              {selectedState === null ? (
                <Col>
                  <Container>
                    <p>Please Select The State To Show The City...</p>
                  </Container>
                </Col>
              ) : (
                <>
                  {data?.length === 0 ? (
                    <>
                      <Tr
                        columns={columns}
                        initialValues={initialValues}
                        title="No City Found"
                        buttonName="Create"
                        btnLink="/city/add-city"
                        titleHeader="Cities"
                      />
                      {/* <Row>
                        <Col>
                     
                          <AllMessage title="No City Found" />
                        </Col>
                      </Row>
                      <Col
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <Link to={"/city/add-city"} className="btn btn-primary">
                          Add City
                        </Link>
                      </Col> */}
                    </>
                  ) : (
                    <List
                      title="Cities"
                      data={data}
                      columns={columns}
                      button="Create"
                      route={`city`}
                      to="add-city"
                      handleDelete={handleDelete}
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={handlePageChange}
                    />
                  )}
                </>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default City;
