import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ListGroup,
  Card,
  Badge,
} from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import { useSelector } from "react-redux";
import "./style.css";
import Api from "../api/Api";
import TimeAgo from "../timeAgo/TimeAgo";
import { Link } from "react-router-dom";
import axios from "axios";
import Spinner from "../../../components/common/spinner/Spinner";
import AllMessage from "../../../components/common/allMessage/AllMessage";
const Notification = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const loggedInUser = useSelector((state) => state.auth.user);
  const userId = loggedInUser?.userDetails?.id;
  const token = loggedInUser?.token;
  const [data, setData] = useState([]);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [isLoading, setIsLoading] = useState(false);
  const notificationApi = Api({
    url: `notify/all/user/${userId}`,
  });
  useEffect(() => {
    setIsLoading(true);
    const fetchPropertyData = async () => {
      try {
        const data = {
          page_number: 1,
          page_size: 20,
          order_by: "ASC",
        };
        const responseData = await notificationApi.postData(data);
        console.log(responseData);
        setData(responseData.data.notifications);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchPropertyData();
  }, []);
  const handleViewDetails = (notification) => {
    setSelectedNotification(notification);
    setShowModal(true);
  };
  const markNotificationAsRead = async (notificationId) => {
    try {
      const formData = new FormData();
      formData.append("isRead", true);
      formData.append("notificationId", notificationId);

      await axios.put(`${baseUrl}/notify/setRead`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      setData(
        data.map((notification) =>
          notification.id === notificationId
            ? { ...notification, is_read: true }
            : notification
        )
      );
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };
  // notify/getCount
  const handleCardClick = async (notificationId) => {
   
    if (
      !data.find((notification) => notification.id === notificationId).is_read
    ) {
      await markNotificationAsRead(notificationId);
    }
  };
  return (
    <Container>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Row className="mt-4 ">
            <Col>
              <h3>All Notifications</h3>
            </Col>
          </Row>
          {data?.length === 0 ? (
            // <h1>No notifications found.</h1>
            <AllMessage title={"No notifications found."} />
          ) : (
            <>
              <Row className="p-4">
                {data.map((singleNotification) => (
                  <Col md={12} className="singleCard mt-4">
                    <Row className="singleCardInside">
                      <Col md={1}>
                        <div>
                          <img
                            style={{
                              width: "3rem",
                              height: "3rem",
                              borderRadius: "1rem",
                            }}
                            src="https://images.unsplash.com/photo-1714329781250-64b9125f689c?q=80&w=1332&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                          />
                        </div>
                      </Col>
                      <Col md={9} className="notificationCard">
                        <div className="notificationCardDescription">
                          <small style={{ color: "#ED7D3A" }}>
                            {singleNotification?.notification_title}
                          </small>
                          <Badge bg="success" style={{ marginLeft: "1rem" }}>
                            For:
                            {singleNotification?.notification_for}
                          </Badge>

                          <p>{singleNotification?.notification_text}</p>
                          <div className="footerCard">
                            <TimeAgo
                              timestamp={singleNotification?.created_on}
                            />
                            {/* <small>10 min ago</small> */}
                            {singleNotification?.is_read === false && (
                              <Badge
                                bg="success"
                                style={{ marginLeft: "1rem" }}
                              >
                                Unread
                              </Badge>
                            )}
                          </div>
                        </div>
                      </Col>
                      <Col md={2} className="buttonData">
                        <button
                          className={`btn btndetail ${
                            singleNotification.is_read ? "" : "read"
                          }`}
                          onClick={() => handleCardClick(singleNotification.id)}
                        >
                          <Link
                            style={{ color: "#fff" }}
                            to={`/notification/${singleNotification?.id}`}
                          >
                            View Details
                          </Link>
                        </button>
                      </Col>
                    </Row>
                  </Col>
                ))}
              </Row>
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default Notification;
