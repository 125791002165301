import React, { useState, useEffect } from "react";
import { Col, Row, Button } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setFormData, nextStep } from "../../../features/formData/formSlice";

const AddPropertyInformation = ({ isPost }) => {
  const loggedInUser = useSelector((state) => state.auth.user);
  console.log(loggedInUser);
  const userType = loggedInUser?.userDetails?.userType;

  const formData = useSelector((state) => state.form.formData);
  const token = loggedInUser?.token;
  const dispatch = useDispatch();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [propertyPurposes, setPropertyPurposes] = useState([]);
  const [propertyTypes, setPropertyTypes] = useState([]);
  const [propertySubtypes, setPropertySubtypes] = useState([]);
  const [selectedPropertyType, setSelectedPropertyType] = useState(
    formData.propertyType || ""
  );
  const [formKey, setFormKey] = useState(Date.now());

  const [selectedPropertyPurpose, setSelectedPropertyPurpose] = useState("");

  const initialValues = {
    propertyPurpose: formData?.propertyPurpose || "",
    userType: formData?.userType || "",
    propertyType: formData?.propertyType || "",
    propertySubtype: formData?.propertySubtype || "",
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    const propertyType = propertyTypes.find(
      (type) => type.id === +values.propertyType
    );
    const propertyPurpose = propertyPurposes.find(
      (purpose) => purpose.id === +values.propertyPurpose
    );

    const formDataInside = {
      ...formData,
      ...values,
      propertyPurposeName: propertyPurpose,
      propertyTypeName: propertyType,
      countryName: "India",
    };

    dispatch(setFormData(formDataInside));
    dispatch(nextStep());
    setSubmitting(false);
  };

  const postData = {
    page_number: 1,
    page_size: 20,
    order_by: "ASC",
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const authToken = await loggedInUser?.token;
        const purposeResponse = await axios.post(
          `${baseUrl}/property/purposes`,
          postData,
          {
            headers: { Authorization: `Bearer ${authToken}` },
          }
        );
        if (purposeResponse.data.statusType === "SUCCESS") {
          console.log("ypur user type is", isPost);
          let purposeData;

          if (!isPost) {
            // Apply the userType-based condition when isPost is false
            if (userType === "SUPER_ADMIN" || userType === "PARTNER") {
              purposeData = purposeResponse.data.propertyPurposes;
            } else {
              purposeData = purposeResponse.data.propertyPurposes.filter(
                (singlePropertyPurpose) =>
                  singlePropertyPurpose?.name !== "Promotional"
              );
            }
          } else {
            // When isPost is true, always filter out "Promotional"
            purposeData = purposeResponse.data.propertyPurposes.filter(
              (singlePropertyPurpose) =>
                singlePropertyPurpose?.name !== "Promotional"
            );
          }
          setPropertyPurposes(purposeData);
        } else {
          setPropertyPurposes([]);
        }

        const typeResponse = await axios.post(
          `${baseUrl}/property/types`,
          postData,
          {
            headers: { Authorization: `Bearer ${authToken}` },
          }
        );
        if (typeResponse.data.statusType === "SUCCESS") {
          setPropertyTypes(typeResponse.data.propertyTypes);
        } else {
          setPropertyTypes([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchSubtypes = async (propertyTypeId) => {
      if (!propertyTypeId) {
        setPropertySubtypes([]);
        return;
      }
      try {
        const response = await axios.post(
          `${baseUrl}/property/sub/types/${propertyTypeId}`,
          postData,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        console.log(response);
        if (response.data.statusType === "SUCCESS") {
          setPropertySubtypes(response.data.propertySubTypes);
        } else {
          setPropertySubtypes([]);
        }
      } catch (error) {
        console.error("Error fetching property subtypes:", error);
        setPropertySubtypes([]);
      }
    };

    fetchSubtypes(selectedPropertyType);
  }, [selectedPropertyType, token]);

  useEffect(() => {
    const fetchUserTypes = async (propertyPurposeId) => {
      if (!propertyPurposeId) return;

      try {
        const response = await axios.get(
          `${baseUrl}/user/types/${propertyPurposeId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        if (response.data.statusType === "SUCCESS") {
          // Update your state or handle the response as needed
        } else {
          // Handle the failure
        }
      } catch (error) {
        console.error("Error fetching user types:", error);
      }
    };

    fetchUserTypes(selectedPropertyPurpose);
  }, [selectedPropertyPurpose, token]);

  const validationSchema = Yup.object({
    propertyType: Yup.string().required("Property type is required"),
    propertyPurpose: Yup.string().required("Property Purpose is required"),
    // userType: Yup.string().required("User Type is required"),
  });

  return (
    <Formik
      key={formKey} // Unique key for each render
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, setFieldValue, values }) => (
        <Form>
          <Row
            className="d-flex align-items-center justify-content-between"
            style={{ width: " 65rem" }}
          >
            <label htmlFor="title" className="form-label mt-1 mb-2">
              I Wish To:
            </label>
            <FieldArray name="propertyPurpose">
              {() => (
                <>
                  {propertyPurposes.map((purpose) => (
                    <Col key={purpose.id}>
                      <div className="form-check ">
                        <input
                          className="form-check-input"
                          type="radio"
                          id={`purpose-${purpose.id}`}
                          value={purpose.id}
                          checked={values.propertyPurpose === purpose.id}
                          onChange={() => {
                            setFieldValue("propertyPurpose", purpose.id);
                            setSelectedPropertyPurpose(purpose.id);
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`purpose-${purpose.id}`}
                        >
                          {purpose.name}
                        </label>
                      </div>
                    </Col>
                  ))}
                </>
              )}
            </FieldArray>
            <ErrorMessage
              name="propertyPurpose"
              component="div"
              className="text-danger"
            />
          </Row>

          <Row className="mt-3">
            {values.propertyPurpose && (
              <Col md={6}>
                <label htmlFor="propertyType" className="form-label">
                  Property Type
                </label>
                <Field
                  as="select"
                  className="form-select"
                  id="propertyType"
                  name="propertyType"
                  onChange={(e) => {
                    setFieldValue("propertyType", e.target.value);
                    setSelectedPropertyType(e.target.value);
                  }}
                >
                  <option value="" label="Select property type" />
                  {propertyTypes.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.name}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="propertyType"
                  component="div"
                  className="text-danger"
                />
              </Col>
            )}
            {values.propertyType && (
              <Col md={6}>
                <label htmlFor="propertySubtype" className="form-label">
                  Property Subtype
                </label>
                <Field
                  as="select"
                  className="form-select"
                  id="propertySubtype"
                  name="propertySubtype"
                  onChange={(e) => {
                    setFieldValue("propertySubtype", e.target.value);
                  }}
                  disabled={!selectedPropertyType}
                >
                  <option value="">Select Property Subtype</option>
                  {propertySubtypes.map((subtype) => (
                    <option key={subtype.id} value={subtype.id}>
                      {subtype.name}
                    </option>
                  ))}
                  {/* Dummy values for testing */}
                  {/* <option value="1">Subtype 1</option>
                  <option value="2">Subtype 2</option>
                  <option value="3">Subtype 3</option> */}
                </Field>
                <ErrorMessage
                  name="propertySubtype"
                  component="div"
                  className="text-danger"
                />
              </Col>
            )}
            {/* {values.propertySubtype && (
              <Col md={4}>
                <label htmlFor="userType" className="form-label">
                  User Type
                </label>
                <Field
                  as="select"
                  className="form-select"
                  id="userType"
                  name="userType"
                >
                  <option value="">Select User Type</option>
                  <option value="buyer">Buyer</option>
                  <option value="seller">Seller</option>
                </Field>
                <ErrorMessage
                  name="userType"
                  component="div"
                  className="text-danger"
                />
              </Col>
            )} */}
          </Row>
          <div className="d-flex justify-content-end mt-3">
            <Button variant="primary" type="submit" disabled={isSubmitting}>
              Next
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddPropertyInformation;
