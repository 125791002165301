import React, { useState, useEffect } from "react";
import Api from "../../components/api/Api";
import { Col, Container, Row, Button } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Notification, {
  showToast,
} from "../../../components/common/notification/Notification";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import Spinner from "../../../components/common/spinner/Spinner";
import { useSelector } from "react-redux";
import SingleDetailHeader from "../../components/singleDetail/SingleDetailHeader";
import axios from "axios";
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import AdminHeader from "../../common/Header";
const AddPropertySubType = () => {
  const loggedInUser = useSelector((state) => state.auth.user);
  const token = loggedInUser?.token;
  const userId = loggedInUser?.userDetails?.id;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [propertySubType, setPropertySubType] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const addPropertyTypeApi = Api({
    url: `property/sub/type/add`,
  });

  const initialValues = {
    name: "",
    description: "",
    state: {
      id: "",
    },
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const authToken = await loggedInUser?.token;
        const data = {
          page_number: 1,
          page_size: 20,
          order_by: "ASC",
        };
        const response = await axios.post(`${baseUrl}/property/types`, data, {
          headers: { Authorization: `Bearer ${authToken}` },
        });
        if (response.data.statusType === "SUCCESS") {
          console.log(response);
          setPropertySubType(response.data.propertyTypes);
        } else {
          setPropertySubType([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true);

      const data = {
        name: values.name.toUpperCase(),
        description: values.description,
        property_type_id: +values.state,
      };
      console.log(data);
      const responseData = await addPropertyTypeApi.postData(data);
      if (responseData.data.statusType === "SUCCESS") {
        showToast("Property Sub Type  added successfully", {
          type: "success",
        });

        setTimeout(() => {
          navigate("/property-subtype");
        }, 3000);
      }
      console.log(data);

      //   console.log(data);
    } catch (error) {
      setIsError(true);
      console.log(error);
      showToast("Error Occured", {
        type: "error",
      });
    } finally {
      setSubmitting(false);
    }
  };
  const breadcrumbItems = [
    { label: "", link: "/admin", icon: "fa-solid fa-house" },
    { label: "Master", link: "/user" },
    { label: "Property Sub Types", link: "/property-subtype" },
    { label: "Add Property SubType", link: "", active: true },
  ];
  return (
    <>
      <Notification />
      <Container fluid style={{ marginTop: "2rem", padding: "0rem 4rem" }}>
        <BreadCrumb items={breadcrumbItems} />
        <Row>
          <Col lg={3} md={4} sm={12}>
            <AdminHeader />
          </Col>
          <Col lg={9} md={8} sm={12}>
            <SingleDetailHeader
              header="Add Property Sub Type"
              description="Add the property sub type details section"
            />
            <Formik
              initialValues={initialValues}
              // validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form>
                  <Col md={12}>
                    <div className="">
                      <label htmlFor="title" className="form-label">
                        Property Type
                      </label>
                      <Field
                        as="select"
                        className="form-control"
                        name="state"
                        required=""
                      >
                        <option value="">-- Select Property Type --</option>
                        {propertySubType &&
                          propertySubType.map((state) => (
                            <option key={state.id} value={state.id}>
                              {state.name}
                            </option>
                          ))}
                      </Field>
                    </div>
                  </Col>
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">
                      Name
                    </label>
                    <Field
                      type="text"
                      className="form-control"
                      id="name"
                      aria-describedby="emailHelp"
                      placeholder="Enter name"
                      name="name"
                    />
                    <ErrorMessage
                      name="title"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="description" className="form-label">
                      Description
                    </label>
                    <Field
                      type="text"
                      className="form-control"
                      id="description"
                      aria-describedby="emailHelp"
                      placeholder="Enter Description"
                      name="description"
                    />
                    <ErrorMessage
                      name="title"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <button
                    type="submit"
                    className="btn btn-primary mt-5"
                    style={{ marginBottom: "10rem" }}
                    disabled={isSubmitting}
                    // disabled={true}
                  >
                    {isSubmitting ? (
                      <Spinner loading={true} />
                    ) : (
                      "Add Property Sub Type"
                    )}
                  </button>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AddPropertySubType;
